<template>
  <div class="home">
    <main-swiper-vue />
    <section class="business-wrap">
      <div class="title-wrap">
        <p class="line-txt">NAVOROS</p>
        <h1>사업분야</h1>
        <p class="sub-txt">
          나보로스는 샘플에서 양산까지<br>
          고객사의 개발 단계에 맞게 서비스를 제공합니다.
        </p>
      </div>
      <ul class="business-contents">
        <li>
          <div class="txt-box">
            <h4>무인비행체</h4>
            <p class="line"></p>
            <p class="info">텍스트를 입력해주세요<br>텍스트를 입력해주세요.</p>
            <div class="direct-btn">
              <router-link to="/drone">바로가기 →</router-link>
            </div>
          </div>
          <div class="opacity-bg"></div>
        </li>
        <li>
          <div class="txt-box">
            <h4>개발진행(HW&SW)</h4>
            <p class="line"></p>
            <p class="info">텍스트를 입력해주세요<br>텍스트를 입력해주세요.</p>
            <div class="direct-btn">
              <router-link to="/development">바로가기 →</router-link>
            </div>
          </div>
          <div class="opacity-bg"></div>
        </li>
        <li>
          <div class="txt-box">
            <h4>시제품제작</h4>
            <p class="line"></p>
            <p class="info">시제품 제작을 통해<br>아이디어를 현실로 구현</p>
            <div class="direct-btn">
              <router-link to="/prodDesign">바로가기 →</router-link>
            </div>
          </div>
          <div class="opacity-bg"></div>
        </li>
        <li>
          <div class="txt-box">
            <h4>PCB 설계/제작</h4>
            <p class="line"></p>
            <p class="info">텍스트를 입력해주세요<br>텍스트를 입력해주세요.</p>
            <div class="direct-btn">
              <router-link to="/pcbProd">바로가기 →</router-link>
            </div>
          </div>
          <div class="opacity-bg"></div>
        </li>
      </ul>
    </section>
    <section class="about-wrap">
      <div class="title-wrap">
        <p class="line-txt">WHO WE ARE</p>
        <h1>NAVOROS</h1>
      </div>
      <ul class="about-contents">
        <li>
          <div class="img-wrap">
            <img src="../assets/img/about1.png" alt="">
          </div>
          <p class="title">도전정신과 열정</p>
          <p class="context">여성창업가의 도전정신과 기술 혁신의 열정으로 무장한 기업입니다.</p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="../assets/img/about2.png" alt="">
          </div>
          <p class="title">연구개발전담부서 운영</p>
          <p class="context">연구개발전담부를 운영하여 끊임없는 기술혁신을 통해 업계를 선도합니다.</p>
        </li>
        <li>
          <div class="img-wrap">
            <img src="../assets/img/about3.png" alt="">
          </div>
          <p class="title">지속가능한 미래</p>
          <p class="context">혁신적인 기술로 안전하고 지속가능한 미래를 구축하는데 전념하겠습니다.</p>
        </li>
      </ul>
    </section>
    <section class="portfolio-wrap">
      <div class="portfolio-contents">
        <div class="title-wrap">
          <div class="left-wrap">
            <p class="line-txt">NAVOROS</p>
            <h1>포트폴리오</h1>
          </div>
          <p class="sub-txt">
            텍스트를 입력해주세요<br>텍스트를 입력해주세요.
          </p>
        </div>
        <portfolio-swiper-vue />
      </div>
      <h3 :style="leftToRightStyle" class="top-navoros na-txt">NAVOROS</h3>
      <h3 :style="rightToLeftStyle" class="bottom-navoros na-txt">NAVOROS</h3>
    </section>
    <section class="notice-wrap">
      <main-notice-vue />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import MainSwiperVue from '@/components/MainSwiper.vue'
import PortfolioSwiperVue from '@/components/PortfolioSwiper.vue'
import MainNoticeVue from '@/components/MainNotice.vue'

export default {
  name: 'MainView',
  components: {
    MainSwiperVue,
    PortfolioSwiperVue,
    MainNoticeVue,
  },
  data() {
    return {
      scrollPosition: 0,
    };
  },
  computed: {
    leftToRightStyle() {
      const startScroll = 2200;
      const endScroll = 3200;
      let translateXValue = 200;
      if (this.scrollPosition >= startScroll) {
        const scrollProgress = (this.scrollPosition - startScroll) / (endScroll - startScroll);
        translateXValue = 100 - scrollProgress * 200; // 100%에서 -100%까지 이동
      }
      return {
        transform: `translateX(${translateXValue}%)`,
      };
    },
    rightToLeftStyle() {
      const startScroll = 2800;
      const endScroll = 3800;
      let translateXValue = -200;
      if (this.scrollPosition >= startScroll) {
        const scrollProgress = (this.scrollPosition - startScroll) / (endScroll - startScroll);
        translateXValue = -100 + scrollProgress * 200; // -100%에서 100%까지 이동
      }
      return {
        transform: `translateX(${translateXValue}%)`,
      };
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      console.log(this.scrollPosition);
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>

<style scoped>
.title-wrap {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}
.title-wrap .line-txt {
  display: inline-block;
  font-size: 24px;
  font-weight: lighter;
  line-height: 34px;
  border-bottom: 1px solid #000;
  letter-spacing: 5px;
}
.title-wrap h1 {
  font-size: 60px;
  margin-top: 20px;
  margin-bottom: 45px;
}
.title-wrap .sub-txt {
  font-size: 24px;
  line-height: 32px;
}
.business-wrap {
  /* outline: 1px solid green; */
  position: relative;
  width: 1200px;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 200px;
}
.business-contents {
  /* outline: 1px solid blue; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 30px;
  text-align: right;
}
.business-contents li {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.business-contents li:nth-child(1) {
  margin-left: 300px;
  background-image: url('../assets/img/business_5.jpg');
}
.business-contents li:nth-child(2) {
  background-image: url('../assets/img/business_1.jpg');
}
.business-contents li:nth-child(3) {
  background-image: url('../assets/img/business_4.jpg');
}
.business-contents li:nth-child(4) {
  background-image: url('../assets/img/business_2.jpg');
}

.business-contents li .txt-box {
  /* background-color: #ccc; */
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 210px;
  padding: 40px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 3;
  transition: top 0.5s ease-in-out;
}
.business-contents li .txt-box h4 {
  font-size: 24px;
  margin-bottom: 2px;
}
.business-contents li .txt-box .line {
  width: 60px;
  border-bottom: 1px solid #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.business-contents li .txt-box .info {
  line-height: 26px;
  margin-bottom: 70px;
  color: #ddd;
  /* opacity: 0; */
}
.business-contents li .txt-box .direct-btn {
  border: 1px solid #fff;
  padding: 4px 10px 8px 10px;
}
.business-contents li .txt-box .direct-btn a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  line-height: 1;
}
.business-contents li .opacity-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
}
.business-contents li:hover .opacity-bg {
  opacity: 0.7;
}
.business-contents li:hover .txt-box {
  top: 0;
}
.business-contents li:hover .line {
  opacity: 1;
}

.about-wrap {
  background-image: url('../assets/img/main_about_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0;
}
.about-wrap .title-wrap {
  position: relative;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
.about-wrap .title-wrap .line-txt {
  border-bottom: 1px solid #fff;
}
.about-wrap .about-contents {
  /* outline: 1px solid yellow; */
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.about-contents li {
  /* outline: 1px solid green; */
  width: 380px;
  text-align: left;
}
.about-contents li .img-wrap {
  width: 100%;
  height: 380px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.about-contents li .title {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 35px;
}
.about-contents li .context {
  font-size: 24px;
  color: #ccc;
  line-height: 30px;
}

.portfolio-wrap {
  position: relative;
  padding: 200px 0;
}
.portfolio-contents {
  width: 1200px;
  margin: 0 auto;
}
.portfolio-contents .title-wrap {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 40px;
  margin-bottom: 100px;
}
.portfolio-contents .title-wrap h1 {
  margin-bottom: 0;
}
.portfolio-wrap .na-txt {
  position: absolute;
  font-size: 200px;
  font-weight: bold;
  color: #f7f7ff;
  line-height: 1;
  z-index: -1;
  transition: transform 0.1s linear;
}
.portfolio-wrap .top-navoros {
  top: 140px;
  left: 0;
}
.portfolio-wrap .bottom-navoros {
  bottom: 100px;
  right: 0;
}


/* .footer-wrap .address li {
  line-height: 20px;
} */
</style>
