<template>
  <div class="portfolio-swiper">
    <swiper
      :slides-per-view="2"
      :space-between="40"
      :loop="true"
      :speed="1000"
      :autoplay='{
        "delay": 3000,
        "disableOnInteraction": false
        }'
        :navigation="{
            nextEl: '.custom-button-next',
            prevEl: '.custom-button-prev',
        }"
    >
    <!-- <swiper
      :slides-per-view="2"
      :space-between="40"
      :loop="true"
      :speed="1500"
      :navigation="{
          nextEl: '.custom-button-next',
          prevEl: '.custom-button-prev',
        }"
    > -->
    <!-- 포폴 페이지로 넘어가는거 있어야 함 -->
      <swiper-slide v-for="(item, index) in pfData" :key="index" class="pf-wrap">
        <div class="contents-box">
            <div class="img-wrap">
              <img :src="item.url" alt="">    
            </div>
            <p class="title">{{ item.title }}</p>
        </div>
      </swiper-slide>
    </swiper>
    <div class="arrow-btn">
        <div class="custom-button-prev custom-btn">
            <div class="triangle triangle-prev"></div>
        </div>
        <div class="custom-button-next custom-btn">
            <div class="triangle triangle-next"></div>
        </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import {Autoplay} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination, Autoplay])

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                el: '.swiper-pagination',
                    clickable: true,
                },
            },
            // pfData: [],
        };
    },
    computed: {
        pfData() {
            return [
                {
                    title: 'test1',
                    url: require('../assets/portfolio/pf1.png')
                },
                {
                    title: 'test2',
                    url: require('../assets/portfolio/pf2.png')
                },
                {
                    title: 'test3',
                    url: require('../assets/portfolio/pf1.png')
                },
                {
                    title: 'test4',
                    url: require('../assets/portfolio/pf2.png')
                },
            ]
        }
    },
    mounted() {

    },
    methods: {
        onSlideChange () {
            // console.log('slide change')
        }
    }
}
</script>

<style scoped>
.portfolio-swiper {
    /* outline: 1px solid blue; */
    width: 100%;
    height: 480px;
    position: relative;
}
.swiper-container {
    /* outline: 1px solid blue; */
    width: 1040px;
    height: 100%;
    margin: 0 auto;
}
.swiper-slide {
    position: relative;
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* overflow: hidden; */
}
.pf-wrap:nth-child(odd) .contents-box {
    position: absolute;
    bottom: 0;
}
.contents-box .img-wrap {
    width: 500px;
    height: 340px;
    border-radius: 20px;
    overflow: hidden;
}
.contents-box .img-wrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.contents-box .title {
    font-size: 24px;
    color: #777;
    text-align: left;
    margin-top: 15px;
    margin-left: 10px;
}
.arrow-btn {
    /* outline: 1px solid green; */
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.arrow-btn .custom-btn {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* 만약 loop안하면 끝일때 화살표 배경 연하게 */
.arrow-btn .custom-btn.swiper-button-disabled {
    background-color: #ccc;
}
.arrow-btn .custom-btn .triangle {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}
.arrow-btn .custom-btn .triangle-prev {
    border-right: 17px solid #fff;
    margin-right: 5px;
}
.arrow-btn .custom-btn .triangle-next {
    border-left: 17px solid #fff;
    margin-left: 5px;
}
</style>