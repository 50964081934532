var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('main-swiper-vue'),_c('section',{staticClass:"business-wrap"},[_vm._m(0),_c('ul',{staticClass:"business-contents"},[_c('li',[_c('div',{staticClass:"txt-box"},[_c('h4',[_vm._v("무인비행체")]),_c('p',{staticClass:"line"}),_vm._m(1),_c('div',{staticClass:"direct-btn"},[_c('router-link',{attrs:{"to":"/drone"}},[_vm._v("바로가기 →")])],1)]),_c('div',{staticClass:"opacity-bg"})]),_c('li',[_c('div',{staticClass:"txt-box"},[_c('h4',[_vm._v("개발진행(HW&SW)")]),_c('p',{staticClass:"line"}),_vm._m(2),_c('div',{staticClass:"direct-btn"},[_c('router-link',{attrs:{"to":"/development"}},[_vm._v("바로가기 →")])],1)]),_c('div',{staticClass:"opacity-bg"})]),_c('li',[_c('div',{staticClass:"txt-box"},[_c('h4',[_vm._v("시제품제작")]),_c('p',{staticClass:"line"}),_vm._m(3),_c('div',{staticClass:"direct-btn"},[_c('router-link',{attrs:{"to":"/prodDesign"}},[_vm._v("바로가기 →")])],1)]),_c('div',{staticClass:"opacity-bg"})]),_c('li',[_c('div',{staticClass:"txt-box"},[_c('h4',[_vm._v("PCB 설계/제작")]),_c('p',{staticClass:"line"}),_vm._m(4),_c('div',{staticClass:"direct-btn"},[_c('router-link',{attrs:{"to":"/pcbProd"}},[_vm._v("바로가기 →")])],1)]),_c('div',{staticClass:"opacity-bg"})])])]),_vm._m(5),_c('section',{staticClass:"portfolio-wrap"},[_c('div',{staticClass:"portfolio-contents"},[_vm._m(6),_c('portfolio-swiper-vue')],1),_c('h3',{staticClass:"top-navoros na-txt",style:(_vm.leftToRightStyle)},[_vm._v("NAVOROS")]),_c('h3',{staticClass:"bottom-navoros na-txt",style:(_vm.rightToLeftStyle)},[_vm._v("NAVOROS")])]),_c('section',{staticClass:"notice-wrap"},[_c('main-notice-vue')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap"},[_c('p',{staticClass:"line-txt"},[_vm._v("NAVOROS")]),_c('h1',[_vm._v("사업분야")]),_c('p',{staticClass:"sub-txt"},[_vm._v(" 나보로스는 샘플에서 양산까지"),_c('br'),_vm._v(" 고객사의 개발 단계에 맞게 서비스를 제공합니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"info"},[_vm._v("텍스트를 입력해주세요"),_c('br'),_vm._v("텍스트를 입력해주세요.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"info"},[_vm._v("텍스트를 입력해주세요"),_c('br'),_vm._v("텍스트를 입력해주세요.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"info"},[_vm._v("시제품 제작을 통해"),_c('br'),_vm._v("아이디어를 현실로 구현")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"info"},[_vm._v("텍스트를 입력해주세요"),_c('br'),_vm._v("텍스트를 입력해주세요.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"about-wrap"},[_c('div',{staticClass:"title-wrap"},[_c('p',{staticClass:"line-txt"},[_vm._v("WHO WE ARE")]),_c('h1',[_vm._v("NAVOROS")])]),_c('ul',{staticClass:"about-contents"},[_c('li',[_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/img/about1.png"),"alt":""}})]),_c('p',{staticClass:"title"},[_vm._v("도전정신과 열정")]),_c('p',{staticClass:"context"},[_vm._v("여성창업가의 도전정신과 기술 혁신의 열정으로 무장한 기업입니다.")])]),_c('li',[_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/img/about2.png"),"alt":""}})]),_c('p',{staticClass:"title"},[_vm._v("연구개발전담부서 운영")]),_c('p',{staticClass:"context"},[_vm._v("연구개발전담부를 운영하여 끊임없는 기술혁신을 통해 업계를 선도합니다.")])]),_c('li',[_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"src":require("../assets/img/about3.png"),"alt":""}})]),_c('p',{staticClass:"title"},[_vm._v("지속가능한 미래")]),_c('p',{staticClass:"context"},[_vm._v("혁신적인 기술로 안전하고 지속가능한 미래를 구축하는데 전념하겠습니다.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap"},[_c('div',{staticClass:"left-wrap"},[_c('p',{staticClass:"line-txt"},[_vm._v("NAVOROS")]),_c('h1',[_vm._v("포트폴리오")])]),_c('p',{staticClass:"sub-txt"},[_vm._v(" 텍스트를 입력해주세요"),_c('br'),_vm._v("텍스트를 입력해주세요. ")])])
}]

export { render, staticRenderFns }